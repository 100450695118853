import React from 'react';
import Content from '../views/Driver/Price';

export default ({location}) => (
  <Content location={location} seo={{
    title: 'Kom nemt i gang med en app til din bil',
    lang: 'da-DK',
    description: 'Få overblik over dit køretøj. Tilmeld dig bil-appen. Udfyld vores korte formular, følg trinnene for at afslutte registreringen og downloade appen.',
    meta: [{
      name: 'keywords',
      content: 'Bil-app'
    }]
  }}/>
);