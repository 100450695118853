import i18n from '../../../locale';
import { driver } from '../../../data/sitemap';

export default [{
  name: driver._,
  text: i18n('Drivers'),
  url: i18n('_url:drivers'),
}, {
  name: driver.price,
  text: i18n('Get started'),
  url: i18n('_url:drivers-pricing'),
}]